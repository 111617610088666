import "./styles.css";
import { langs } from "./constants";
import _ from "lodash";
import { useState } from "react";

export default function App() {
  const [trans, setTrans] = useState(langs.en.trans);

  const changeLang = (n) => {
    setTrans(langs[n]?.trans);
  };

  return (
    <div className="angry-grid">
      <div id="item-0">
        <ul id="list">
          {_.values(langs).map((l) => {
            return (
              <li onClick={() => changeLang(l.id)} key={l.name}>
                <p
                  style={{
                    fontWeight: "bold",
                    marginRight: 5,
                    cursor: "pointer"
                  }}
                  href={`#${l.id}`}
                >
                  {l.name}
                </p>
              </li>
            );
          })}
        </ul>
      </div>
      <div id="item-2">
        <h1>{trans.welcome}</h1>
        <p>{trans.desc}</p>
      </div>
      <div id="item-3">
        <img
          alt="logo"
          style={{ height: 300, width: 300 }}
          src={"/hajde_logo.png"}
        />
      </div>
      <iframe
        title="Konum-Location"
        width="100%"
        height="327"
        id="gmap_canvas"
        src="https://maps.google.com/maps?q=Rafailovici%20budva&t=&z=13&ie=UTF8&iwloc=&output=embed"
        frameBorder="0"
        scrolling="no"
        marginHeight="0"
        marginWidth="0"
      ></iframe>
    </div>
  );
}
