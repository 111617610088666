export const langs = {
  tr: {
    id: "tr",
    name: "Türkçe",
    trans: {
      welcome: "Hoşgeldiniz!",
      desc: "Steakhouse restorantımız yeni açılmıştır"
    }
  },
  en: {
    id: "en",
    name: "English",
    trans: {
      welcome: "Welcome!",
      desc: "Our Steakhouse has just opened"
    }
  },
  srb: {
    id: "srb",
    name: "Serbian",
    trans: {
      welcome: "Добродошли!",
      desc: "Наш стеакхоусе је управо отворен"
    }
  },
  ru: {
    id: "ru",
    name: "Russian",
    trans: {
      welcome: "Добро пожаловать!",
      desc: "Наш стейк-хаус только что открылся"
    }
  }
};
